import moment from 'moment';
import React from 'react';
import { DrawerForm } from '@/components/drawer-form';
import { mapToExtremeTimeValues, mapToMoment, mapToString } from '@/components/table/filters/date-filter';

export const getPreparedFilters = (selectedFilters) => {
  if (!Object.entries(selectedFilters).length) {
    return {};
  }
  let filters = {};
  for (const [key, item] of Object.entries(selectedFilters)) {
    if (typeof item !== 'undefined' && item != null) {
      filters = {
        ...filters,
        [key]: Array.isArray(item) ? item : [item],
      };
    }
  }
  return filters;
};

export const transformFilters = (filters) => Object.entries(filters).map(([key, value]) => {
  if (value && Array.isArray(value)) {
    return value.length === 1 ? { [key]: value[0] } : { [key]: value };
  }
  return {};
}).reduce((accum, item) => ({
  ...accum,
  ...item,
}), {});

export const applyFiltersToColumns = (columns, filters, sorters) => columns.map((column) => {
  const sortOrder = sorters.columnKey === column.key ? sorters.order : null;

  if (!Object.keys(filters).includes(column.dataIndex) || typeof filters[column.dataIndex] === 'undefined' || filters[column.dataIndex] === null) {
    return {
      ...column,
      filteredValue: null,
      sortOrder,
    };
  }
  return {
    ...column,
    filteredValue: Array.isArray(filters[column.dataIndex]) ? filters[column.dataIndex] : [filters[column.dataIndex]],
    sortOrder,
  };
});

export const mapFiltersToTableFilters = (filters, filtersSetup, currentFilters = []) => {
  const filtersSetupNames = filtersSetup.map(({ name }) => name);

  const tableFilters = Object.entries(currentFilters)
    .reduce((acc, [key, value]) => {
      if (value !== null && !filtersSetupNames.includes(key)) {
        acc[key] = value;
      }

      return acc;
    }, {});

  filtersSetup.forEach(({ name, componentName }) => {
    if (typeof filters[name] === 'undefined' || filters[name] == null) {
      delete filters[name];
      return;
    }
    switch (componentName) {
      case 'date-range-picker':
        filters = {
          ...filters,
          ...tableFilters,
          [name]: (mapToString(mapToExtremeTimeValues(filters[name]))),
        };
        break;
      default:
        break;
    }
  });
  return filters;
};

export const mapTableFiltersToFilters = (filters, filtersSetup) => {
  filtersSetup.forEach(({ name, componentName }) => {
    switch (componentName) {
      case 'date-range-picker':
        filters = {
          ...filters,
          [name]: filters[name]
            && Array.isArray(filters[name]) && filters[name].every((date: moment.Moment) => moment.isMoment(date))
            ? mapToMoment(filters[name])
            : null,
        };
        break;
      default:
        break;
    }
  });
  return filters;
};

export const FiltersTable = ({
  filters, setFilters, onClose, title, visible, filtersSetup = [],
}) => {
  const resourceController = {
    create: (values: any) => {
      const modifiedValues = mapFiltersToTableFilters(values, filtersSetup, filters);
      setFilters(getPreparedFilters(modifiedValues));
      return Promise.resolve()
    },
  };

  return (
    <DrawerForm
      width={800}
      title={title}
      initValues={mapTableFiltersToFilters(filters, filtersSetup)}
      formFields={filtersSetup}
      loaderCondition={false}
      resourceController={resourceController}
      onClose={onClose}
      visible={visible}
      resetFieldsBeforeDrawerOpened
    />
  );
};
