import { useCallback, useEffect, useState } from 'react';
import { Transition } from '@uirouter/react';
import { api } from '@/api';
import { TimesheetPageState } from '../../../enums';
import { weekTimesheetStore } from '@/stores';
import { getDayTimesheetColumn } from '@/pages/timesheet/components/day-timesheet/setup';

export const useDayTimesheetData = ({
  weekTimesheetId,
  transition,
}: {
  weekTimesheetId: string;
  weeks: number[];
  transition: Transition;
}): {
  loading: boolean;
  list: mpg.api.dayTimesheet.DayTimesheet[];
  columns: any[];
  onDoubleClick: ({ id }: { id: string }) => void;
} => {
  const [list, setList] = useState<mpg.api.dayTimesheet.DayTimesheet[]>([]);
  const columns = getDayTimesheetColumn();
  const weekTimesheet = weekTimesheetStore.items.find(({ id }) => id === weekTimesheetId);

  const [loading, setLoading] = useState<boolean>(true);

  const loadTimesheetList = useCallback(async () => {
    setLoading(true);
    const { source } = api.dayTimesheet.getAll({ weekTimesheetId, limit: 10, sort: 'timesheetDate:1' });
    const {
      data: { items = [] },
    } = await source;

    setList(
      items.map((item) => ({
        ...item,
        canEdit: weekTimesheet && !weekTimesheet.isApproved,
      })),
    );
    setLoading(false);
  }, [weekTimesheetId, weekTimesheet]);

  useEffect(() => {
    loadTimesheetList();
  }, [loadTimesheetList]);

  const onDoubleClick = (record) => {
    transition.router.stateService.go(TimesheetPageState.DayTimesheetDetails, { recordId: record.id });
  };

  return {
    list,
    loading,
    columns,
    onDoubleClick,
  };
};
