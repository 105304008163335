import { useEffect } from 'react';

import {
  agencyStore,
  breaktimeStore,
  buildingStore,
  buildingsAreasStore,
  colorStore,
  shiftStore,
  supervisorStore,
  dayTimesheetWorkingLogsStore,
} from '@/stores';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getColumns } from './day-timesheet-working-logs-setup';

const store = dayTimesheetWorkingLogsStore;

export const useWorkingLogData = ({ recordId }: { recordId: string }) => {
  const { loading } = useMassUpdateCashedStores([
    agencyStore,
    breaktimeStore,
    breaktimeStore,
    buildingStore,
    buildingsAreasStore,
    colorStore,
    shiftStore,
    supervisorStore,
  ]);

  useEffect(() => {
    store.asyncItems({ id: recordId });
  }, [recordId]);

  return {
    loading: loading || dayTimesheetWorkingLogsStore.loading,
    items: dayTimesheetWorkingLogsStore.items,
    store: dayTimesheetWorkingLogsStore,
    columns: getColumns(),
  };
};
