import { UIView, UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';

import { api } from '@/api';
import { DrawerForm } from '@/components/drawer-form';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import { PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY } from '@/constants/permissions';
import {
  authStore, buildingStore, calendarShiftPlanningStore, colorStore, shiftStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValue } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';
import { EmployeeType, WorkScheduleType } from '@/types/enums';
import { Form as AntdForm } from '@/components/antd';
import { getDateWithPartOfDay } from '@/utils/date';

import { useValueChanges } from './hooks';
import { getFormFields } from './setup';

const entity = 'coordinator-temps-shift-planning';
const store = calendarShiftPlanningStore;

const normalizeDate = (date: string, time: moment.Moment): moment.Moment => moment(date).set({
  hour: time.hour(),
  minute: time.minute(),
  seconds: time.second(),
}).utcOffset(0, true);

export const BulkCreate = observer(({ transition }: UIViewInjectedProps) => {
  const [formRef] = AntdForm.useForm();
  const { selectedDates = [], buildingId } = transition.targetState().params();

  const { loading } = useMassUpdateCashedStores([
    buildingStore,
    colorStore,
    shiftStore,
  ]);

  const onBack = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const initValues = {
    eventStart: [moment('07:00:00', 'HH:mm:ss'), moment('19:00:00', 'HH:mm:ss')],
    buildingId,
    additionalTime: 0,
    employeeType: EmployeeType.Temporary,
    workSchedule: WorkScheduleType.Shift,
  };

  const { disabledShifts, shiftsOptions, onValuesChange } = useValueChanges({
    formRef,
    initialShiftsDisable: false,
    initValues,
  });

  const formTitle = () => {
    const title = getTranslatedString('shiftPlanning.create-new');
    const dates = selectedDates
      .sort((a, b) => a.diff(b))
      .map((datesItem: moment.Moment) => datesItem.format('MM/DD/YYYY'))
      .join(', ');

    return (
      <div>
        <div>{title}</div>
        <div className="m-shift-planning__drawer-subtitle">{dates}</div>
      </div>
    );
  };

  const formFields = getFormFields({
    disabledShifts,
    shiftsOptions,
    isBulkCreate: true,
    hasAdminRole: authStore.hasAdminRole(),
  });

  const allowedFields = getAllowedFormFields(formFields, true, PERMISSION_OBJ_SHIFT_PLANNING_TEMPORARY);

  const resourceController = withoutRepeatValue(initValues, {
    create: (values: mpg.api.shiftPlanning.ShiftPlanningParams) => {
      const items = selectedDates.map((date) => {
        const eventStart = normalizeDate(date, moment(values.eventStart[0]));
        let eventEnd = normalizeDate(date, moment(values.eventStart[1]));

        if (eventStart.diff(eventEnd) > 0) {
          eventEnd = eventEnd.add(1, 'day');
        }

        const title = getDateWithPartOfDay(eventStart);

        return {
          ...values,
          title,
          eventStart,
          eventEnd,
          employeeType: EmployeeType.Temporary,
          workSchedule: WorkScheduleType.Shift,
        };
      });

      return api.shiftPlanningTemps.bulkCreate({ items }).source
        .then(() => store.refresh());
    },
  });

  return (
    <>
      <DrawerForm
        form={formRef}
        className="form-grid-wrapper"
        title={formTitle()}
        initValues={initValues}
        formFields={allowedFields}
        resourceController={resourceController}
        loaderCondition={loading}
        layout="vertical"
        onClose={onBack}
        onValuesChange={onValuesChange}
      />
      <UIView />
    </>
  );
});
