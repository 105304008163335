import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Space } from '@/components/antd';

import { getDateWithoutTime } from '@/utils/moment';
import { withPermissions } from '@/hocs/permission';
import { PERMISSION_DELETE, PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_UPDATE } from '@/constants/permissions';
import { DeleteAction, EditAction } from '@/components/table/actions';
import { api } from '@/api';
import { employeeMarkupsStore } from '@/stores';
import { showError } from '@/utils/common';

const PermissionedEditAction = withPermissions([[PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_UPDATE]])(EditAction);
const PermissionedDeleteAction = withPermissions([[PERMISSION_OBJ_EMPLOYEE_MARKUP, PERMISSION_DELETE]])(DeleteAction);

const getActions = (employeeId: string, entity: string) => {
  const Actions = (_: any, { id }: any) => {
    const onDelete = () => {
      api.employeeMarkups
        .delete(id)
        .source.then(() => employeeMarkupsStore.refresh())
        .catch(showError);
    };

    return (
      <Space>
        <PermissionedEditAction
          uiSref={{
            to: `base-layout.${entity}.edit.edit-employee-markup`,
            params: { markupId: id, employeeId },
          }}
        />
        <PermissionedDeleteAction onConfirm={onDelete} />
      </Space>
    );
  };

  return Actions;
};

export const getColumns = ({ employeeId, entity }: { employeeId: string; entity: string }) => [
  {
    title: <FormattedMessage id="employeeMarkup.baseRate" />,
    dataIndex: 'baseRate',
  },
  {
    title: <FormattedMessage id="employeeMarkup.effectiveDate" />,
    dataIndex: 'effectiveDate',
    render: (item: string) => getDateWithoutTime(item),
  },
  {
    title: <FormattedMessage id="table.actions" />,
    dataIndex: 'actions',
    render: getActions(employeeId, entity),
  },
];

const RenderAuditField = (key: any, { originalData, newData }: any) => {
  const wasChanged = originalData !== newData;

  return (
    <Space
      style={{
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        color: wasChanged ? 'red' : undefined,
      }}
    >
      {key === undefined ? '' : String(key)}
    </Space>
  );
};

export const auditColumns = [
  {
    title: <FormattedMessage id="audit.property" />,
    dataIndex: 'key',
  },
  {
    title: <FormattedMessage id="audit.originalData" />,
    dataIndex: 'originalData',
    render: RenderAuditField,
  },
  {
    title: <FormattedMessage id="audit.newData" />,
    dataIndex: 'newData',
    render: RenderAuditField,
  },
];

export const getFormFields = () => [
  {
    name: 'baseRate',
    componentName: 'input-number',
    params: {
      i18nLabel: 'employeeMarkup.baseRate',
      min: 0,
    },
    rules: [{ required: true }],
  },
  {
    name: 'effectiveDate',
    componentName: 'datepicker',
    params: {
      i18nLabel: 'employeeMarkup.effectiveDate',
      format: 'MM/DD/YYYY',
    },
    rules: [{ required: true }],
  },
];
