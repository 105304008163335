import {
  getFormattedParams, getFormattedParamsWithWorkScedule, request, paramsWithTimeZone, getParamsWithMappedBirthdayField,
} from '@/utils/request';
import { WorkScheduleType } from '@/types/enums';
import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';

const prepareGetAllParams = (params?: mpg.api.permanentEmployees.GetParams) => {
  const query = new URLSearchParams();
  query.append('workSchedule', WorkScheduleType.StandardHours);
  const formattedParams = getFormattedParams(params, true);
  return { query, formattedParams: getParamsWithMappedBirthdayField(formattedParams) };
};

export default {
  getAll(params?: mpg.api.permanentEmployees.GetParams): ApiResponse<WrappedResult<mpg.api.permanentEmployees.PermanentEmployee[]>> {
    const { query, formattedParams } = prepareGetAllParams(params);

    return request({
      method: 'get',
      url: `/permanent-employees?${query}`,
      params: formattedParams,
    });
  },
  get(id: mpg.api.permanentEmployees.PermanentEmployeeId): ApiResponse<WrappedResult<mpg.api.permanentEmployees.PermanentEmployee>> {
    return request({
      method: 'get',
      url: `permanent-employees/${id}`,
    });
  },
  create(data: mpg.api.permanentEmployees.PermanentEmployeeStandardHoursParams): ApiResponse<WrappedResult<mpg.api.permanentEmployees.PermanentEmployee>> {
    return request({
      method: 'post',
      url: '/permanent-employees',
      data,
    });
  },
  update(id: number, data: mpg.api.permanentEmployees.PermanentEmployeeStandardHoursParams): ApiResponse<WrappedResult<mpg.api.permanentEmployees.PermanentEmployee>> {
    return request({
      method: 'patch',
      url: `permanent-employees/${id}`,
      data: getParamsWithMappedBirthdayField(data),
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/permanent-employees/${id}`,
    });
  },
  bulkCreate(data: { items: mpg.api.permanentEmployees.PermanentEmployeeStandardHoursParams[] }): ApiResponse<WrappedResult<mpg.api.permanentEmployees.PermanentEmployee[]>> {
    const formattedData = getFormattedParamsWithWorkScedule(data, WorkScheduleType.StandardHours);
    return request({
      method: 'post',
      url: '/permanent-employees/bulk/create',
      data: formattedData,
    });
  },
  bulkUpdate(data: { items: mpg.api.permanentEmployees.PermanentEmployeeStandardHoursParams[] }): ApiResponse<WrappedItemsResult<mpg.api.permanentEmployees.PermanentEmployee[]>> {
    return request({
      method: 'post',
      url: '/permanent-employees/bulk/update',
      data,
    });
  },
  bulkDelete(data: { ids: string[] }): ApiResponse<WrappedResult<mpg.api.permanentEmployees.PermanentEmployee[]>> {
    return request({
      method: 'post',
      url: '/permanent-employees/bulk/delete',
      data,
    });
  },
  exportToExcel(params?: mpg.api.permanentEmployees.GetParams): ApiResponse<WrappedResult<Blob>> {
    const { query, formattedParams } = prepareGetAllParams(params);

    return request({
      method: 'post',
      url: `/permanent-employees/export?${query}`,
      ...paramsWithTimeZone(formattedParams),
      config: {
        responseType: 'blob',
      },
    });
  },
  exportToEmail(params?: mpg.api.permanentEmployees.GetParams): ApiResponse<WrappedResult<Blob>> {
    const { query, formattedParams } = prepareGetAllParams(params);

    return request({
      method: 'post',
      url: `/permanent-employees/export-by-link?${query}`,
      ...paramsWithTimeZone(formattedParams),
    });
  },
  audit(params: mpg.api.permanentEmployees.PermanentEmployeeAuditGetParams): ApiResponse<WrappedItemsResult<mpg.api.permanentEmployees.PermanentEmployeeAudit>> {
    const { id, ...decreasedParams } = params;

    return request({
      method: 'get',
      url: `/permanent-employees/${id}/audit`,
      params: decreasedParams,
    });
  },
};
