import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Space } from '@/components/antd';

const Render = (key: any, { originalData, newData }: any) => {
  const wasChanged = originalData !== newData;

  return (
    <Space style={{
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      background: 'white',
      color: wasChanged
        ? 'red'
        : undefined,
    }}
    >
      {key === undefined ? '' : String(key)}
    </Space>
  );
};

export const columns = [
  {
    title: <FormattedMessage id="audit.property" />,
    dataIndex: 'key',
  },
  {
    title: <FormattedMessage id="audit.originalData" />,
    dataIndex: 'originalData',
    render: Render,
  },
  {
    title: <FormattedMessage id="audit.newData" />,
    dataIndex: 'newData',
    render: Render,
  },
];
