import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import moment from 'moment/moment';
import { api } from '@/api';
import { getAllowedFormFields } from '@/components/generic-form/utils';
import {
  authStore,
  buildingStore,
  colorStore,
  shiftPlanningPermsStore,
  shiftStore,
} from '@/stores';
import { getTranslatedString } from '@/utils';
import { withoutRepeatValueForBulkUpdate } from '@/utils/controller';
import { useMassUpdateCashedStores } from '@/utils/store';
import { PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT } from '@/constants/permissions';
import { DrawerForm } from '@/components/drawer-form';
import { SIDEBAR_WIDTH } from '@/constants';
import { Form as AntdForm, RuleObject } from '@/components/antd';
import { EmployeeType } from '@/types/enums';

import { getFormFields } from './setup';
import { useValueChanges } from './hooks';

const entity = 'coordinator-perms-shift-planning';
const store = shiftPlanningPermsStore;

export const BulkForm = observer(({ transition }: UIViewInjectedProps) => {
  const { selectedRowKeys = [], selectedBuilding } = transition.targetState().params();
  const [formRef] = AntdForm.useForm();

  const { loading } = useMassUpdateCashedStores([
    buildingStore,
    colorStore,
    shiftStore,
  ]);



  const onClose = () => {
    transition.router.stateService.go(`base-layout.${entity}`);
  };

  const title = getTranslatedString('shiftPlanning.bulk-update');
  const initValues = {
    buildingId: selectedBuilding?.id,
  };

  const { disabledShifts, shiftsOptions, onValuesChange } = useValueChanges({
    formRef,
    initialShiftsDisable: false,
    initValues,
  });

  const formFields = getFormFields({
    isBulkEdit: true,
    disabledShifts,
    shiftsOptions,
    hasAdminRole: authStore.hasAdminRole(),
  })
    .map((item) => ({ ...item, rules: (item.rules as RuleObject[]).filter(({ required }) => !required) }));

  const allowedFields = getAllowedFormFields(formFields, false, PERMISSION_OBJ_SHIFT_PLANNING_PERMANENT);

  const resourceController = withoutRepeatValueForBulkUpdate(initValues, {
    create: (values: mpg.api.shiftPlanning.ShiftPlanningParams) => {
      const eventStart = values?.eventStart && moment(values.eventStart[0]).utcOffset(0, true);
      const eventEnd = values?.eventStart && moment(values.eventStart[1]).utcOffset(0, true);

      const items = selectedRowKeys.map((id: string) => ({
        ...values,
        id,
        eventStart,
        eventEnd,
        employeeType: EmployeeType.Permanent,
      }));

      return api.shiftPlanningPerms.bulkUpdate({ items }).source.then(() => store.refresh());
    },
  });
  useEffect(() => {
    if (!selectedRowKeys.length) {
      onClose();
    }
  }, []);
  return (
    <DrawerForm
      form={formRef}
      onClose={onClose}
      title={title}
      initValues={initValues}
      formFields={allowedFields}
      resourceController={resourceController}
      loaderCondition={loading}
      onValuesChange={onValuesChange}
      width={(window.innerWidth - SIDEBAR_WIDTH) / 2}
    />
  );
});
