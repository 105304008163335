import Menu from 'antd/lib/menu';
import { Dropdown } from 'antd';
import Button from 'antd/lib/button';
import { FormattedMessage } from 'react-intl';
import Modal from 'antd/lib/modal';
import { useState } from 'react';
import Select from 'antd/lib/select';
import { DownloadOutlined, DownOutlined } from '@/components/icons';
import { useExport } from '@/pages/common/components/buttons/hooks';
import { api } from '@/api';
import { showError } from '@/utils/common';
import { timesheetDateTransformer } from '@/utils/date';
import { useTimesheetWeeks } from '@/utils/hooks';

export const DropdownExport = ({ store }) => {
  const { exportMode: weekTimesheetExport, onExport: onWeekTimesheetExport } = useExport(
    store,
    api.weekTimesheet.exportToExcel,
    'week-timesheet',
    api.weekTimesheet.exportToEmail,
  );

  const { exportMode: dayTimesheetExport, onExport: onDayTimesheetExport } = useExport(
    store,
    api.weekTimesheet.exportDayTimesheetToExcel,
    'week-timesheet',
    api.weekTimesheet.exportDayTimesheetToEmail,
  );

  const { exportMode: restrictedWeekTimesheetExport, onExport: onRestrictedWeekTimesheetExport } = useExport(
    store,
    api.weekTimesheet.exportRestrictedTimesheetToExcel,
    'week-timesheet',
    api.weekTimesheet.exportRestrictedTimesheetToEmail,
  );

  const { exportMode: magnitExport, onExport: onMagnitExport } = useExport(
    store,
    api.weekTimesheet.exportMagnitToExcel,
    'week-timesheet',
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentExportKey, setCurrentExportKey] = useState('');
  const [weekOf, setWeekOf] = useState<string>();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const weeks = useTimesheetWeeks();

  const showModal = () => {
    setIsModalOpen(true);
  };
  function handleMenuClick(e) {
    if (e.key === 'week-timesheet-export') {
      onWeekTimesheetExport('xlsx');
    } else {
      showModal();
      setCurrentExportKey(e.key);
    }
  }

  const handleOk = async () => {
    try {
      setConfirmLoading(true);
      const additionalParams = {
        weekOf: [weekOf],
      };
      const { skip, limit, ...params } = store.params;
      const weekTimesheet = await api.weekTimesheet.getAll({ ...additionalParams, ...params }).source;
      const { count } = weekTimesheet.data;
      if (currentExportKey === 'week-daily-details-export') {
        await onDayTimesheetExport('xlsx', additionalParams, count);
      }
      if (currentExportKey === 'week-export-with-restricted-fields') {
        await onRestrictedWeekTimesheetExport('xlsx', additionalParams, count);
      }
      if (currentExportKey === 'magnit-export') {
        await onMagnitExport('csv', additionalParams, count);
      }
    } catch (e) {
      showError(e);
    } finally {
      setIsModalOpen(false);
      setConfirmLoading(false);
      setWeekOf(undefined);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setWeekOf(undefined);
  };
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="week-timesheet-export" icon={<DownloadOutlined />}>
        <FormattedMessage id="timesheet.week-timesheet-export" />
      </Menu.Item>
      <Menu.Item key="week-daily-details-export" icon={<DownloadOutlined />}>
        <FormattedMessage id="timesheet.week-daily-details-export" />
      </Menu.Item>
      <Menu.Item key="week-export-with-restricted-fields" icon={<DownloadOutlined />}>
        <FormattedMessage id="timesheet.week-export-with-restricted-fields" />
      </Menu.Item>
      <Menu.Item key="magnit-export" icon={<DownloadOutlined />}>
        <FormattedMessage id="timesheet.magnit-export" />
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown
        disabled={
          weekTimesheetExport === 'xlsx' || dayTimesheetExport === 'xlsx' || restrictedWeekTimesheetExport === 'xlsx' || magnitExport === 'xlsx'
        }
        overlay={menu}
      >
        <Button type="primary" shape="round" icon={<DownOutlined style={{ marginRight: '8px' }} />}>
          <FormattedMessage id="timesheet.export-to-excel" />
        </Button>
      </Dropdown>
      <Modal
        title={<FormattedMessage id={`timesheet.${currentExportKey}`} />}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okButtonProps={{ disabled: !weekOf }}
      >
        <Select
          placeholder={<FormattedMessage id="timesheet.weekOf-select" />}
          onChange={(value) => setWeekOf(value)}
          value={weekOf}
          options={weeks.map((value: number) => ({ value, label: timesheetDateTransformer.from(value) }))}
        />
      </Modal>
    </>
  );
};
