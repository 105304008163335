import { getFormattedParams, request, requestTo, getParamsWithMappedBirthdayField } from '@/utils/request';
import { ApiResponse, WrappedItemsResult, WrappedResult } from './types';

const prepareGetAllParams = (params?: mpg.api.employees.GetParams) => {
  const formattedParams = getFormattedParams(params);
  return getParamsWithMappedBirthdayField(formattedParams);
};

export const paramsWithTimeZone = (params: any) => ({
  params: {
    ...params,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
});

export default {
  getAll(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<mpg.api.employees.Employee[]>> {
    return requestTo('employees', params, '', prepareGetAllParams);
  },
  get(id: mpg.api.employees.EmployeeId): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'get',
      url: `employees/${id}`,
    });
  },
  create(data: mpg.api.employees.EmployeeParams): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'post',
      url: '/employees',
      data,
    });
  },
  update(id: number, data: mpg.api.employees.EmployeeParams): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'patch',
      url: `employees/${id}`,
      data: getParamsWithMappedBirthdayField(data),
    });
  },
  delete(id: number): ApiResponse<WrappedResult<any>> {
    return request({
      method: 'delete',
      url: `/employees/${id}`,
    });
  },
  bulkCreate(data: {
    items: mpg.api.employees.EmployeeParams[];
  }): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'post',
      url: '/employees/bulk/create',
      data,
    });
  },
  bulkUpdate(data: {
    items: mpg.api.employees.Employee[];
  }): ApiResponse<WrappedItemsResult<mpg.api.employees.Employee[]>> {
    return request({
      method: 'post',
      url: '/employees/bulk/update',
      data,
    });
  },
  bulkDelete(data: { ids: string[] }): ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'post',
      url: '/employees/bulk/delete',
      data,
    });
  },
  exportToExcel(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<Blob>> {
    return requestTo('employees', params, 'export', prepareGetAllParams);
  },
  exportToEmail(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<Blob>> {
    return requestTo('employees', params, 'export-by-link', prepareGetAllParams);
  },
  exportBaseRateToExcel(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<Blob>> {
    return request({
      method: 'post',
      url: '/employees/export-with-base-rate',
        ...paramsWithTimeZone({ ...prepareGetAllParams(params) }),
      config:  { responseType: 'blob' },
    });
  },
  exportBaseRateToEmail(params?: mpg.api.employees.GetParams): ApiResponse<WrappedResult<Blob>> {
    return request({
      method: 'post',
      url: '/employees/export-with-base-rate-by-link',
      ...paramsWithTimeZone({ ...prepareGetAllParams(params) }),
    });},
  audit(
    params: mpg.api.employees.EmployeeAuditGetParams,
  ): ApiResponse<WrappedItemsResult<mpg.api.employees.EmployeeAudit>> {
    const { id, ...decreasedParams } = params;

    return request({
      method: 'get',
      url: `/employees/${id}/audit`,
      params: decreasedParams,
    });
  },
  inviteToWebPortal(id: string):  ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'post',
      url: `/employees/${id}/register-employee-user`,
    });
  },
  revokeFromWebPortal(id: string):  ApiResponse<WrappedResult<mpg.api.employees.Employee>> {
    return request({
      method: 'post',
      url: `/employees/${id}/revoke-employee-user`,
    });
  },
  bulkInviteToWebPortal(data: { ids: string[] }):  ApiResponse<WrappedItemsResult<mpg.api.employees.Employee[]>> {
    return request({
      method: 'post',
      url: `/employees/bulk/register-employee-users`,
      data,
    });
  },
  bulkRevokeFromWebPortal(data: { ids: string[] }):  ApiResponse<WrappedItemsResult<mpg.api.employees.Employee[]>> {
    return request({
      method: 'post',
      url: `/employees/bulk/revoke-employee-users`,
      data,
    });
  },
};
