import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { UIView, useCurrentStateAndParams } from '@uirouter/react';

import { FormattedMessage } from 'react-intl';
import { Button, PageHeader } from '@/components/antd';
import { SettingOutlined } from '@/components/icons';
import { DayTimesheetTable } from '@/pages/timesheet/components/day-timesheet/day-timesheet-table';
import { useWeekTimesheetData } from '@/pages/timesheet/components/table/hooks';
import { TimesheetPageState } from '@/pages/timesheet/enums';

export const DayTimesheet = observer(({ transition }: any) => {
  const tableRef: any = useRef({});
  const { weeks } = useWeekTimesheetData();

  // use arrow function as tableRef is mutated in child component
  const onSettingsClick = () => tableRef.current.showSettingsModal();
  const { params } = useCurrentStateAndParams();
  const { id } = params;
  const onBack = () => {
    transition.router.stateService.go(TimesheetPageState.BaseLayout);
  };

  return (
    <>
      <PageHeader
        title={[
          <Button type="primary" shape="round" size="large" key="setting" onClick={onBack}>
            <FormattedMessage id="timesheet.backButton" />
          </Button>,
        ]}
        extra={[<Button size="middle" key="setting" icon={<SettingOutlined />} onClick={onSettingsClick} />]}
      />
      <DayTimesheetTable tableRef={tableRef} weekTimesheetId={id} weeks={weeks} transition={transition} />
      <UIView />
    </>
  );
});
