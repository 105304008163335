import { Transition } from '@uirouter/react';

import { api } from '@/api';
import { weekTimesheetStore } from '@/stores';
import { withoutRepeatValueOnlyForUpdate } from '@/utils/controller';
import { useCurrentBuildingConfig, useFormDataById } from '@/utils/hooks';
import { FormBuilderOptionItem, GenericFormResourceController } from '@/components/generic-form';

import { getFormFields } from './setup';
import { TimesheetPageState } from '../../enums';

const getInitialValue = (value: number, correctedValue: number | null): number =>
  correctedValue === null ? value : correctedValue;

export const useAdditionalInformationData = (
  dayTimesheet?: mpg.api.dayTimesheet.DayTimesheet,
): {
  loading: boolean;
  buildingConfig: Partial<mpg.api.buildingConfig.BuildingConfig>;
} => {
  const { buildingConfig, loading } = useCurrentBuildingConfig(dayTimesheet?.buildingId, dayTimesheet?.weekOf);

  return {
    loading,
    buildingConfig,
  };
};

export const useDayTimesheetForm = ({
  transition,
}: {
  transition: Transition;
}): {
  resourceController: GenericFormResourceController;
  formFields: FormBuilderOptionItem[];
  loading: boolean;
  initValues: any;
  onBack: () => void;
  recordId: string;
  dayTimesheet?: mpg.api.dayTimesheet.DayTimesheet;
  canEdit: boolean;
} => {
  const { recordId } = transition.router.globals.params;
  const { formData: dayTimesheet, loading } = useFormDataById<mpg.api.dayTimesheet.DayTimesheet>(
    recordId,
    'dayTimesheet',
  );
  const { formData: weekTimesheet, loading: weekTimesheetLoading } =
    useFormDataById<mpg.api.weekTimesheet.WeekTimesheet>(dayTimesheet?.weekTimesheetId, 'weekTimesheet');

  const onBack = () => {
    transition.router.stateService.go(TimesheetPageState.DayTimesheetTable);
  };

  const initValues = dayTimesheet
    ? {
        workingBillableCorrectedTime:
          getInitialValue(dayTimesheet.workingBillableTime, dayTimesheet.workingBillableCorrectedTime) ?? 0,
        brakePaidCorrectedTime: getInitialValue(dayTimesheet.brakePaidTime, dayTimesheet.brakePaidCorrectedTime) ?? 0,
        lunchPaidCorrectedTime: getInitialValue(dayTimesheet.lunchPaidTime, dayTimesheet.lunchPaidCorrectedTime) ?? 0,
        lunchUnpaidCorrectedTime:
          getInitialValue(dayTimesheet.lunchUnpaidTime, dayTimesheet.lunchUnpaidCorrectedTime) ?? 0,
        bathPaidCorrectedTime: getInitialValue(dayTimesheet.bathPaidTime, dayTimesheet.bathPaidCorrectedTime) ?? 0,
        transitPaidCorrectedTime:
          getInitialValue(dayTimesheet.transitPaidTime, dayTimesheet.transitPaidCorrectedTime) ?? 0,
      }
    : {};
  const formFields = getFormFields({
    isTransitTimeNull: getInitialValue(dayTimesheet?.transitPaidTime, dayTimesheet?.transitPaidCorrectedTime),
    isBrakePaidTimeNull: getInitialValue(dayTimesheet?.brakePaidTime, dayTimesheet?.brakePaidCorrectedTime),
    isBathPaidTimeNull: getInitialValue(dayTimesheet?.bathPaidTime, dayTimesheet?.bathPaidCorrectedTime),
    isLunchPaidTimeNull: getInitialValue(dayTimesheet?.lunchPaidTime, dayTimesheet?.lunchPaidCorrectedTime),
    isLunchUnpaidTimeNull: getInitialValue(dayTimesheet?.lunchUnpaidTime, dayTimesheet?.lunchUnpaidCorrectedTime),
  });

  const resourceController = withoutRepeatValueOnlyForUpdate(initValues, {
    update: (values: mpg.api.employees.Employee) =>
      api.dayTimesheet.update(dayTimesheet.id, { ...values }).source.then(() => weekTimesheetStore.refresh()),
  });
  return {
    resourceController,
    formFields,
    loading: loading && weekTimesheetLoading,
    initValues,
    onBack,
    recordId,
    dayTimesheet,
    canEdit: weekTimesheet && !weekTimesheet.isApproved,
  };
};
