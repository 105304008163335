import { Transition } from '@uirouter/react';
import { Table } from '@/components/table';

import { useDayTimesheetData } from '../table/hooks';
import { DAY_TABLE_STORAGE_KEY } from '../../constants';

export const DayTimesheetTable = ({
  transition,
  tableRef,
  weekTimesheetId,
  weeks,
}: {
  weekTimesheetId: string;
  tableRef: any;
  weeks: number[];
  transition: Transition;
}) => {

  const { list, loading, columns, onDoubleClick } = useDayTimesheetData({ weekTimesheetId, weeks, transition });
  return (
    <Table
      tableRef={tableRef}
      localStorageKey={DAY_TABLE_STORAGE_KEY}
      rowKey="id"
      columns={columns}
      pagination={false}
      dataSource={list}
      checkPermissions
      loading={loading}
      scroll={{ y: '100%' }}
      onRow={(record) => ({ onDoubleClick: () => onDoubleClick(record) })}
    />
  );
};
