import { Rule } from 'antd/lib/form';
import moment from 'moment/moment';

import { WorkScheduleType } from '@/types/enums';
import { buildDropdownOptionsFromEnum, buildStoreOptions, keyPressedNumbers } from '@/utils/common';
import { startDateAfterNow } from '@/utils/form-rules';
import { buildingStore, colorStore } from '@/stores';
import { DATE_FORMAT, TIME_FORMAT } from '@/utils/moment';

interface Props {
  isCreate?: boolean;
  isBulkEdit?: boolean;
  hasAdminRole?: boolean;
  disabledShifts?: boolean;
  shiftsOptions?: Array<mpg.api.shifts.Shift>;
}

const disabledDate = (current: moment.Moment) => current && current < moment().startOf('day');

export const getFormFields = (
  {
    isCreate = false,
    hasAdminRole = false,
    disabledShifts,
    shiftsOptions = [],
  }: Props,
) => [
  {
    name: 'title',
    componentName: 'input',
    params: {
      i18nLabel: 'shiftPlanning.title',
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'description',
    componentName: 'input',
    params: {
      i18nLabel: 'shiftPlanning.description',
    },
    rules: [
      // { required: true },
    ],
  },
  {
    name: 'eventStart',
    componentName: 'date-range-picker',
    params: {
      i18nLabel: 'shiftPlanning.eventRange',
      format: DATE_FORMAT,
      showTime: {
        format: TIME_FORMAT,
        use12Hours: true,
        secondStep: 5,
        minuteStep: 5,
        defaultValue: [moment('07:00:00', TIME_FORMAT), moment('07:00:00', TIME_FORMAT)],
      },
      disabledDate: !hasAdminRole && disabledDate,
    },
    rules: [
      !hasAdminRole && startDateAfterNow(),
      { required: true },
    ].filter(Boolean),
  },
  {
    name: 'buildingId',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'shiftPlanning.building',
      options: buildStoreOptions(buildingStore, 'name'),
      disabled: !isCreate,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'shifts',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'shiftPlanning.shifts',
      options: buildStoreOptions({ items: shiftsOptions }, 'name'),
      mode: 'multiple',
      disabled: disabledShifts,
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'workSchedule',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'shiftPlanning.workSchedule',
      options: buildDropdownOptionsFromEnum([WorkScheduleType.ShiftWork, WorkScheduleType.StandardHours], 'shiftPlanning.workSchedule.permanent.'),
    },
    rules: [
      { required: true },
    ],
  },
  {
    name: 'additionalTime',
    componentName: 'input-number',
    params: {
      i18nLabel: 'shiftPlanning.additionalTime',
      onKeyPress: keyPressedNumbers,
    },
    rules: [
      // { required: false },
      { type: 'number', min: 0 },
    ] as Rule[],
  },
  {
    name: 'colors',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'shiftPlanning.colors',
      options: buildStoreOptions(colorStore, 'name'),
      mode: 'multiple',
    },
    rules: [
      { required: true },
    ],
  },
];
