import React from 'react';
import {
  Form, FormItemProps, Select, SelectProps,
} from '@/components/antd';

import { Ii18nLabel } from '@/types/common';

import { getFormItemLabel } from './utils';

const { Option } = Select;

interface DropdownProps extends SelectProps<any>, FormItemProps, Ii18nLabel {
  children: any;
  formState: any;
  description?: any;
}

// tslint:disable-next-line:function-name
export function Dropdown(
  {
    allowClear = true,
    options,
    name,
    label,
    i18nLabel,
    rules,
    showSearch = true,
    optionFilterProp = 'children',
    formState,
    description,
    ...props
  }: DropdownProps,
) {
  const fieldLabel = getFormItemLabel(i18nLabel, label);
  const getPopupContainer = (trigger) => trigger.parentElement;
  return (
    <Form.Item name={name} label={fieldLabel} rules={rules} extra={description}>
      <Select
        {...props}
        getPopupContainer={getPopupContainer}
        allowClear={allowClear}
        showSearch={showSearch}
        optionFilterProp={optionFilterProp}
      >
        {options.map((item: any) => <Option key={item.value} value={item.value}>{item.label}</Option>)}
      </Select>
    </Form.Item>
  );
}
