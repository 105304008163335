import React from 'react';
import { FormattedMessage } from 'react-intl';
import { minutesToStringHours } from '@/utils/time';
import { BooleanValueRender } from '@/components/table/renders';

export const getColumns = (): any[] => {
  const columns: Array<Record<string, unknown>> = [
    {
      title: <FormattedMessage id="employee-performance.date" />,
      dataIndex: 'date',
      permissionSubject: 'employeeDetails',
    },
    {
      title: <FormattedMessage id="employee-performance.shiftAssigned" />,
      dataIndex: 'shiftAssigned',
      permissionSubject: 'raw',
    },
    {
      title: <FormattedMessage id="employee-performance.building" />,
      dataIndex: 'building',
      permissionSubject: 'raw',
    },
    {
      title: <FormattedMessage id="employee-performance.grossTime" />,
      dataIndex: 'grossTime',
      permissionSubject: 'employeeDetails',
      render: (value) => minutesToStringHours(value),
    },
    {
      title: <FormattedMessage id="employee-performance.netTime" />,
      dataIndex: 'netTime',
      permissionSubject: 'employeeDetails',
      render: (value) => minutesToStringHours(value),
    },
    {
      title: <FormattedMessage id="employee-performance.totalAdditionTime" />,
      dataIndex: 'totalAdditionTime',
      permissionSubject: 'employeeDetails',
      render: (value) => minutesToStringHours(value),
    },
    {
      title: <FormattedMessage id="employee-performance.totalBillableTime" />,
      dataIndex: 'totalBillableTime',
      permissionSubject: 'employeeDetails',
      render: (value) => minutesToStringHours(value),
    },
    {
      title: <FormattedMessage id="employee-performance.lateArrival" />,
      dataIndex: 'lateArrival',
      permissionSubject: 'agencyDetails',
      render: BooleanValueRender,
    },
    {
      title: <FormattedMessage id="employee-performance.earlyDeparture" />,
      dataIndex: 'earlyDeparture',
      permissionSubject: 'shiftDetails',
      render: BooleanValueRender,
    },
    {
      title: <FormattedMessage id="employee-performance.notShowedUp" />,
      dataIndex: 'notShowedUp',
      permissionSubject: 'shiftDetails',
      render: BooleanValueRender,
    },
  ];
  return columns;
};
