import { Typography } from 'antd';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { UIViewInjectedProps } from '@uirouter/react';
import { Button, Form, notification, Select, Space } from '@/components/antd';
import { getTranslatedString } from '@/utils';
import { PrinterOutlined } from '@/components/icons';
import { employeePerformanceStore } from '@/stores';
import { PerformanceReportState } from '@/pages/employee-performance/enums';
import { minutesToStringHours } from '@/utils/time';
import { getWeekDateRange } from '@/utils/date';

const { Title, Text } = Typography;

const rowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const labelStyle = {
  marginRight: '5px'
}

const subtitleStyle: CSSProperties = {
  textTransform: 'uppercase',
  marginBottom: '12px',
  fontWeight: 700,
  fontSize: '14px'
};

const innerSubtitleStyle: CSSProperties = {
  textTransform: 'uppercase',
  marginRight: '5px',
  fontSize: '14px',
  fontWeight: 700
};

export const WeeklyPerformanceReport = observer(({ transition }: UIViewInjectedProps) => {
  const {
    weeklyPerformanceReport,
    loadWeekOfs,
    weekOptions,
    loadWeeklyData,
    loadDetailedData,
    week,
    printerAvailable,
    printWidth,
    printing,
    getPrinterStatus
  } = employeePerformanceStore;
  const printRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loadWeekOfs(transition);
    getPrinterStatus();
  }, []);

  const handleWeekChange = (value: number) => {
    loadWeeklyData(value);
  };

  const handleRedirect = () => {
    transition.router.stateService.go(PerformanceReportState.DetailedWeeklyReportPage);
    loadDetailedData(transition);
  };

  const handlePrint = async () => {
    try {
      await employeePerformanceStore.startPrint();
      await employeePerformanceStore.sendForPrinting(printRef?.current as Node);
    } catch (e) {
      notification.error({ message: getTranslatedString('employee-performance.print'), duration: 5 });
    } finally {
      await employeePerformanceStore.finishPrint();
    }
  };

  return (
    <Form className="weekly-report">
      <Space direction="vertical" style={{ width: '100%', maxWidth: '580px' }}>
        <Text style={{ fontSize: '20px' }}>
          <FormattedMessage id="employee-performance.datepicker-label" />
        </Text>
        <Form.Item>
          <Select defaultValue={week} onChange={(value) => handleWeekChange(value)} options={weekOptions} />
        </Form.Item>
        {weeklyPerformanceReport ? (
          <>
            <hr style={{border: '1px solid #D9DEE4'}}/>
            <div
              ref={printRef}
              style={{
                backgroundColor: 'white',
                width: printWidth,
                padding: printWidth !== 'auto' ? '15px': 'unset',
                fontSize: '20px'
              }}
            >
              <div style={{ ...rowStyle, marginBottom: '20px' }}>
                <Text style={innerSubtitleStyle}><FormattedMessage id="employee-performance.range" /></Text>
                <Text strong>{getWeekDateRange(week)}</Text>
              </div>

              <div style={subtitleStyle}>
                <FormattedMessage id="employee-performance.worked-hours" />
              </div>
              <div style={{ ...rowStyle, marginBottom: '20px' }}>
                <Text style={labelStyle}><FormattedMessage id="employee-performance.total-billable-time"/></Text>
                <Text strong>{minutesToStringHours(weeklyPerformanceReport?.totalBillableTime)}h</Text>
              </div>

              <div style={subtitleStyle}>
                <FormattedMessage id="employee-performance.billable-hours"/>
              </div>
              <div style={rowStyle}>
                <Text style={labelStyle}><FormattedMessage id="employee-performance.base-billable-time" /></Text>
                <Text strong>{minutesToStringHours(weeklyPerformanceReport?.baseBillableTime)}h</Text>
              </div>
              <div style={{ ...rowStyle, marginBottom: '20px' }}>
                <Text style={labelStyle}><FormattedMessage id="employee-performance.overtime-hours" /></Text>
                <Text strong>{minutesToStringHours(weeklyPerformanceReport?.overtimeTime)}h</Text>
              </div>

              <div style={subtitleStyle}>
                <FormattedMessage id="employee-performance.discipline" />
              </div>
              <div style={rowStyle}>
                <Text style={labelStyle}><FormattedMessage id="employee-performance.missed-shifts" /></Text>
                <Text strong>{weeklyPerformanceReport?.missedShifts}</Text>
              </div>
              <div style={{...rowStyle, marginBottom: '20px'}}>
                <Text style={labelStyle}><FormattedMessage id="employee-performance.under-works-shifts" /></Text>
                <Text strong>{weeklyPerformanceReport?.underWorksShifts}</Text>
              </div>

              <div style={{...rowStyle, marginBottom: '20px'}}>
                <Text style={innerSubtitleStyle}>
                  <FormattedMessage id="employee-performance.approval-status" />
                </Text>
                <Text strong>
                  {getTranslatedString(`employee-performance.approval-${weeklyPerformanceReport?.isApproved}`)}
                </Text>
              </div>
            </div>
            <Button disabled={printing} onClick={handleRedirect} style={{ width: '100%' }} type="primary">
              {getTranslatedString('employee-performance.show-details')}
            </Button>
            <Button disabled={!printerAvailable || printing} style={{ width: '100%' }} onClick={handlePrint} icon={<PrinterOutlined />}>
              {getTranslatedString('employee-performance.print')}
            </Button>
          </>
        ) : null}
      </Space>

      {printing ? (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            top: 0,
            bottom: 0,
            width: '100%',
            zIndex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white'
        }}
        >
          <Title level={3}>
            <FormattedMessage id="employee-performance.printing-text" />
          </Title>
        </div>
      ) : null}
    </Form>
  );
});
