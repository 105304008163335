import { FormattedMessage } from 'react-intl';

import { Button, Space, Tooltip } from '@/components/antd';
import { PERMISSION_READ } from '@/constants/permissions';
import { withPermissions } from '@/hocs/permission';
import { getTranslatedString } from '@/utils';
import { EyeOutlined } from '@/components/icons';
import { DateComponent } from '@/components/form-date';

const auditActionsMap = {
  C: 'Create',
  R: 'Read',
  U: 'Update',
  D: 'Delete',
};

const Action = (_: any, { action }: any) => <Space>{auditActionsMap[action] || ''}</Space>;

export const getColumns = (permissionObject: string, onViewClick: (auditRecordId: number) => void) => {
  const Actions = withPermissions([[permissionObject, PERMISSION_READ]])((record: { id: number }) => (
    <Space>
      <Tooltip title={getTranslatedString('table.view')}>
        <Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={() => onViewClick(record.id)} />
      </Tooltip>
    </Space>
  ));

  const Difference = (_: any, { newData: recordNewData, originalData: recordOriginalData }: any) => {
    const newData = recordNewData || {};
    const originalData = recordOriginalData || {};

    const keys = Object.keys({ ...newData, ...originalData });

    const changedKeys = keys.filter((key) => newData[key] !== originalData[key]).sort();

    return (
      <Space
        style={{
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {keys.length === changedKeys.length ? 'All' : changedKeys.join(', ')}
      </Space>
    );
  };

  const User = ({ name }: any) => <Space>{name || 'deleted'}</Space>;

  return [
    {
      title: <FormattedMessage id="audit.user" />,
      dataIndex: 'user',
      render: User,
    },
    {
      title: <FormattedMessage id="audit.action" />,
      dataIndex: 'action',
      render: Action,
    },
    {
      title: <FormattedMessage id="audit.createdAt" />,
      dataIndex: 'createdAt',
      render: DateComponent,
    },
    {
      title: <FormattedMessage id="audit.changedFields" />,
      dataIndex: 'changedFields',
      render: Difference,
      minWidth: 350,
    },
    {
      title: <FormattedMessage id="table.actions" />,
      conditionalRender: false,
      render: Actions,
      minWidth: 80,
    },
  ].map((column) => ({
    ...column,
    sorter: false,
    filters: null,
    filterDropdown: null,
  }));
};

const RenderAuditField = (key: any, { originalData, newData }: any) => {
  const wasChanged = originalData !== newData;

  return (
    <Space
      style={{
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        background: 'white',
        color: wasChanged ? 'red' : undefined,
      }}
    >
      {key === undefined ? '' : String(key)}
    </Space>
  );
};

export const auditColumns = [
  {
    title: <FormattedMessage id="audit.property" />,
    dataIndex: 'key',
  },
  {
    title: <FormattedMessage id="audit.originalData" />,
    dataIndex: 'originalData',
    render: RenderAuditField,
  },
  {
    title: <FormattedMessage id="audit.newData" />,
    dataIndex: 'newData',
    render: RenderAuditField,
  },
];
