import { Rule } from 'antd/lib/form';
import moment from 'moment/moment';

import { buildDropdownOptionsFromEnum, buildStoreOptions, keyPressedNumbers } from '@/utils/common';
import { startDateAfterNow } from '@/utils/form-rules';
import { buildingStore, colorStore } from '@/stores';
import { DATE_FORMAT, TIME_FORMAT } from '@/utils/moment';
import { WorkScheduleTemporaryType } from '@/types/enums';

interface Props {
  isBulkCreate?: boolean;
  isBulkEdit?: boolean;
  isCreate?: boolean;
  disabledShifts?: boolean;
  shiftsOptions?: Array<mpg.api.shifts.Shift>;
  hasAdminRole?: boolean;
}

export const disabledDate = (current: moment.Moment) => current && current < moment().startOf('day');

export const getFormFields = (
  {
    isBulkCreate = false,
    isCreate = false,
    disabledShifts,
    shiftsOptions = [],
    hasAdminRole = false,
  }: Props,
) => [
    {
      name: 'title',
      conditionalRender: () => !(isBulkCreate || isCreate),
      componentName: 'input',
      params: {
        i18nLabel: 'shiftPlanning.title',
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'description',
      componentName: 'input',
      params: {
        i18nLabel: 'shiftPlanning.description',
      },
      rules: [
        // { required: true },
      ],
    },
    {
      name: 'workSchedule',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'shiftPlanning.workSchedule',
        options: buildDropdownOptionsFromEnum(WorkScheduleTemporaryType, 'shiftPlanning.workSchedule.temporary.'),
      },
      rules: [{ required: true }],
    },
    isBulkCreate ? {
      name: 'eventStart',
      componentName: 'time-range-picker',
      params: {
        i18nLabel: 'shiftPlanning.eventTimeRange',
        order: false,
        format: TIME_FORMAT,
        use12Hours: true,
        secondStep: 5,
        minuteStep: 5,
        disabledDate: !hasAdminRole && disabledDate,
      },
      rules: [
        { required: true },
      ],
    }
      : {
        name: 'eventStart',
        componentName: 'date-range-picker',
        params: {
          i18nLabel: 'shiftPlanning.eventRange',
          format: DATE_FORMAT,
          showTime: {
            format: 'h:mm:ss A',
            use12Hours: true,
            secondStep: 5,
            minuteStep: 5,
            defaultValue: [moment('07:00:00', TIME_FORMAT), moment('07:00:00', TIME_FORMAT)],
          },
          disabledDate: !hasAdminRole && disabledDate,
        },
        rules: [
          !hasAdminRole && startDateAfterNow(),
          { required: true },
        ].filter(Boolean),
      },
    {
      name: 'buildingId',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'shiftPlanning.building',
        options: buildStoreOptions(buildingStore, 'name'),
        disabled: !isBulkCreate && !isCreate,
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'shifts',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'shiftPlanning.shifts',
        options: buildStoreOptions({ items: shiftsOptions }, 'name'),
        mode: 'multiple',
        disabled: disabledShifts,
      },
      rules: [
        { required: true },
      ],
    },
    {
      name: 'additionalTime',
      componentName: 'input-number',
      params: {
        i18nLabel: 'shiftPlanning.additionalTime',
        onKeyPress: keyPressedNumbers,
      },
      rules: [
        // { required: false },
        { type: 'number', min: 0 },
      ] as Rule[],
    },
    {
      name: 'colors',
      componentName: 'dropdown',
      params: {
        i18nLabel: 'shiftPlanning.colors',
        options: buildStoreOptions(colorStore, 'name'),
        mode: 'multiple',
      },
      rules: [
        { required: true },
      ],
    },
  ];

export const getAssignColorsFormFields = () => [
  {
    name: 'colors',
    componentName: 'dropdown',
    params: {
      i18nLabel: 'shiftPlanning.colors',
      options: buildStoreOptions(colorStore, 'name'),
      mode: 'multiple',
    },
    rules: [
      { required: true },
    ],
  },
];